'use client';

import React from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'components/ui/button';

import { MEMBER_ROUTES, OWNER_ROUTES } from 'utils/routes';
import { useLoginUserMutation } from 'generated/hooks';
import { useFormErrorHandling } from 'hooks/useFormErrorHandling';
import { communityUrlFromWindow, GENERAL_SUBDOMAIN } from 'app/_auth/subdomains';
import { UsersYkmeUserInviteStatusChoices } from 'generated/types';
import { LoaderCircle } from 'lucide-react';
import { Input } from 'components/ui/input';
import { getOneClickLandingLinkRedirect } from 'utils/oneClickLanding';
import Link from 'next/link';

type LoginFormProps = {
  community: string;
};

type LoginInputs = {
  email: string;
  password: string;
};

export const LoginForm = (props: LoginFormProps) => {
  const params = useSearchParams();
  const router = useRouter();

  const {
    handleSubmit,
    setError,
    formState: { errors },
    control
  } = useForm<LoginInputs>();
  const { handleErrors } = useFormErrorHandling();
  const [loginState, loginMutation] = useLoginUserMutation();

  const onSubmit = async (inputs: LoginInputs) => {
    const vars = {
      email: inputs.email,
      password: inputs.password
    };
    const context = {
      fetchOptions: { headers: { 'X-Community-Subdomain': props.community } }
    };
    const resp = await loginMutation(vars, context);

    if (resp.error) {
      return handleErrors<LoginInputs>(resp.error, setError, { username: 'email' });
    }

    const oneClickLandingRedirect = getOneClickLandingLinkRedirect();
    if (oneClickLandingRedirect) {
      return router.push(oneClickLandingRedirect);
    }

    const url = communityUrlFromWindow(window, resp.data?.login?.community?.subdomain ?? '', resp.data?.login?.community?.domain);

    if (resp.data?.login?.user?.inviteStatus === UsersYkmeUserInviteStatusChoices.Pending) {
      return router.push(`${OWNER_ROUTES.PENDING.getPath()}`);
    }

    if (!resp.data?.login?.community?.id) {
      return router.push(OWNER_ROUTES.START.getPath());
    }

    if (resp.data?.login?.user?.id && resp.data?.login?.community) {
      let next = OWNER_ROUTES.PANEL.getPath();
      if (window.location.hostname.startsWith(GENERAL_SUBDOMAIN)) {
        next = MEMBER_ROUTES.MEMBERS.getPath();
      }
      if (params?.get("redirect")) {
        next = params?.get("redirect") || "";
      }

      router.push(`${url}${next}`);
    }
  };
  return (
    <div className={"w-full"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"flex flex-col gap-5 text-dark-blue"}>
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            defaultValue={""}
            render={({ field, formState: { errors } }) => (
              <Input
                label='Email'
                placeholder='Enter your email'
                type={'email'}
                id={'email'}
                aria-invalid={errors.email ? 'true' : 'false'}
                errorText={errors.email?.message as string}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required" }}
            defaultValue={""}
            render={({ field }) => (
              <Input
                label='Password'
                type='password'
                placeholder='••••••••'
                aria-invalid={errors.password ? 'true' : 'false'}
                id={'password'}
                errorText={errors.password?.message as string}
                {...field}
              />
            )}
          />
          {errors.root && (
            <div className={"w-full text-xs text-red-600"}>{errors.root.message}</div>
          )}

          <div className={'text-center'}>
            <Link href={OWNER_ROUTES.FORGOT_PASSWORD.getPath()}
                  className='text-[14px]/[20px] font-regular text-gray-500 hover:underline'>
              Forgot password?
            </Link>
          </div>

          <div className="text-center">
            <Button
              disabled={loginState.fetching}
              type="submit"
              variant="primaryBrand"
              size="large"
              className={'px-4 w-full gap-2'}
            >
              {loginState.fetching && <LoaderCircle className="animate-spin" />}
              {"Sign in"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
