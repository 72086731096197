import Cookies from 'js-cookie';
import { OWNER_ROUTES } from 'utils/routes';

export const ONE_CLICK_LANDING_COOKIES_KEY = 'ONE_CLICK_LANDING_INVITE_LINK';
export const ONE_CLICK_LANDING_QUERY_PARAM = 'oneClickLandingLink';


export const getOneClickLandingLinkRedirect = () => {
  const value = Cookies.get(ONE_CLICK_LANDING_COOKIES_KEY);
  if (!value) return;
  return `${OWNER_ROUTES.START.getPath()}?${ONE_CLICK_LANDING_QUERY_PARAM}=${encodeURIComponent(value)}`;
};
