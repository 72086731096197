export const GENERAL_SUBDOMAIN = 'app';


export const communityUrlFromWindow = (window: any, subdomain: string, domain?: string | null) => {
  /*
   * Builds community url from window object
   * window.location.hostname could be:
   * 1. <subdomain>.gokyg.com
   * 2. <app>.gokyg.com GENERAL_SUBDOMAIN
   * 3. custom-domain.io - custom domain
   *
   * if custom domain is present, we return custom domain
   * else: we replace <app>/<subdomain> with subdomain
   */
  let host;

  if (domain) {
    host = domain;
  } else {
    host = window.location.hostname.replace(GENERAL_SUBDOMAIN, subdomain);
  }

  const port = window.location.port ? `:${window.location.port}` : '';
  return `${window.location.protocol}//${host}${port}`;
};

export const generalHostFromWindow = (window: any): string | undefined => {
  let host = window.location.hostname;
  const subdomain = host.split('.')[0];
  if (subdomain == GENERAL_SUBDOMAIN) {
    // already on GENERAL_SUBDOMAIN
    return host
  }

  if (host.includes('.localhost.io') || host.includes('.staging.gokyg.com') || host.includes('.gokyg.com')) {
    // possible case:
    // 1. <subdomain>.localhost.io - local dev
    // 2. <subdomain>.staging.gokyg.com - staging
    // 3. <subdomain>.gokyg.com - production
    // redirect should be to GENERAL_SUBDOMAIN
    host = host.replace(subdomain, GENERAL_SUBDOMAIN, 1);
    return host
  }

  // other possibles cases:
  // kiite.co
  // uan.vc
  // these are kinda custom domains and also considered as general,
  // so fine with them
};
